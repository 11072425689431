import React from 'react'
import SEOTools from '../../../components/SEOTools'
import { graphql } from 'gatsby'
import { Col, Container, Row } from 'react-bootstrap'
import { getSrc } from 'gatsby-plugin-image'
import HeroUnit from '../../../components/HeroUnit'
import Layout from '../../../components/Layout'

/**
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
const SiruPayPage = ({ data }) => {
    return (
        <Layout>
            <SEOTools
                title='SiruPay Demo'
                description='See our payment gateway in action!'
                image={getSrc(data.heroBg.childImageSharp)}>
                <script async src='https://static.proto.io/api/widget-embed.js' />
            </SEOTools>

            <HeroUnit backgroundImage={data.heroBg} className='dark-background-overlay'>
                <Row className='d-flex align-items-center justify-content-center text-center text-white my-5'>
                    <Col xs='8'>
                        <h1>Demo</h1>
                    </Col>
                </Row>
            </HeroUnit>

            <Container>
                <section>
                    <Row>
                        <Col md={{ offset: 1, span: 4 }} className='d-flex align-items-center'>
                            <div className='py-5'>
                                <h2>Calling method</h2>
                                <p>
                                    With the Siru Mobile payment solution, any mobile phone or tablet becomes a payment
                                    tool.
                                </p>
                            </div>
                        </Col>
                        <Col md={{ offset: 1, span: 5 }} className='d-flex justify-content-center'>
                            <div
                                className='protoio-embed-prototype'
                                data-code='OZZVA8'
                                data-show-sidebuttons='0'
                                style={{ width: '100%', height: '800px' }}
                            />
                        </Col>
                    </Row>

                    <hr className='mb-5' />

                    <Row className='mb-5'>
                        <Col md={{ offset: 1, span: 4, order: 12 }} className='d-flex align-items-center'>
                            <div className='py-5'>
                                <h2>One-click method</h2>
                                <p>
                                    With the one-click payment method users can make purchases instantly when mobile
                                    network is turned on.
                                </p>
                            </div>
                        </Col>
                        <Col md={{ offset: 1, span: 5 }} className='d-flex justify-content-center'>
                            <div
                                className='protoio-embed-prototype'
                                data-code='0F3B1J'
                                data-show-sidebuttons='0'
                                style={{ width: '100%', height: '800px' }}
                            />
                        </Col>
                    </Row>
                </section>
            </Container>
        </Layout>
    )
}

export const query = graphql`
    query {
        heroBg: file(relativePath: { eq: "products/sirupay/sirupay_hero.jpg" }) {
            childImageSharp {
                gatsbyImageData(width: 3840, placeholder: BLURRED, transformOptions: { cropFocus: CENTER })
            }
        }
    }
`

export default SiruPayPage
